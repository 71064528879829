import styled from 'styled-components';

import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  margin: auto;
`;

export const Heading = styled.h1`
  ${font('display', 'lg', '700')}
  color: ${colors.gray[900]};

  ${atMinWidth.sm`
    ${font('display', 'xl', '700')}
  `}

  ${atMinWidth.lg`
    ${font('display', 'xxl', '700')}
  `}
`;

export const Subhead = styled.p`
  ${font('text', 'xl', '400')}
  color: ${colors.gray[600]};
`;
